import React, { useContext } from 'react'
import { Page } from '@types'
import {
  Layout,
  SEO,
  Section,
  HeroHeading,
  Button,
  Box,
  Paragraph,
  Icon,
  VCenter,
  TablePriceList,
} from '@components'
import { graphql } from 'gatsby'
import { Container, Tr, Th, Tbody, Td } from '@bootstrap-styled/v4'
import { usePriceList, useLabels } from '@hooks'
import { ThemeContext } from 'styled-components'
import { stripTag } from '@utils'

const CenikPage: React.FC<Page<unknown>> = ({ data, location }) => {
  const { title, content } = data.wpgraphql.page
  const { transitionDurations, colors } = useContext(ThemeContext)
  const priceList = usePriceList()
  const labels = useLabels()

  return (
    <Layout>
      <SEO title={title} pageUrl={location.pathname} />

      <Section hasBg verticalPadding>
        <Container>
          <div>
            <HeroHeading
              className="mb-5"
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            >
              {title}
            </HeroHeading>
          </div>
          {content && (
            <div
              className="mb-5"
              dangerouslySetInnerHTML={{ __html: content }}
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            />
          )}
          {priceList.length > 0 && (
            <>
              <Box className="mb-5" background={colors.white}>
                <TablePriceList className="mb-0" hover>
                  <Tbody>
                    {priceList.map(item => (
                      <Tr key={item.id}>
                        <Th scope="row" className="font-weight-normal">
                          <Paragraph as="div" textSize="xlarge" className="mb-2">
                            {item.title}
                          </Paragraph>
                          {item.content && (
                            <Paragraph
                              as="div"
                              textSize="small"
                              color={colors.gray500}
                              dangerouslySetInnerHTML={{ __html: stripTag(item.content, 'p') }}
                            />
                          )}
                        </Th>
                        {item.duration && (
                          <Td>
                            <Paragraph as="span" srOnly>
                              {labels.duration}
                            </Paragraph>
                            <VCenter className="text-nowrap">
                              <Icon name="Clock" size={16} color={colors.primary} />
                              <span>{item.duration}</span>
                            </VCenter>
                          </Td>
                        )}
                        <Td className="text-right font-weight-bold text-nowrap">{item.price}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </TablePriceList>
              </Box>
              <div className="text-center">
                <Button color="primary" to="/kontakt/" size="lg">
                  {labels.booking}
                </Button>
              </div>
            </>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

export default CenikPage

export const pageQuery = graphql`
  query {
    wpgraphql {
      page(id: "13", idType: DATABASE_ID) {
        slug
        title(format: RENDERED)
        uri
        isFrontPage
        content(format: RENDERED)
      }
    }
  }
`
